import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/",
		name: "index",
		component: () => import("../views/index/index.vue"),
		meta: {
            title: '丝路里'
        }
	}, {
		path: "/newscenter",
		name: "newscenter",
		component: () => import("../views/newscenter/newscenter.vue"),
		meta: {
            title: '新闻中心'
        }
	}, {
		path: "/newsdetail",
		name: "newsdetail",
		component: () => import("../views/newsdetail/newsdetail.vue"),
		meta: {
            title: '新闻详情'
        }
	}, {
		path: "/team",
		name: "team",
		component: () => import("../views/team/team.vue"),
		meta: {
            title: '丝路团队'
        }
	}, {
		path: "/serviceitems",
		name: "serviceitems",
		component: () => import("../views/serviceitems/servicesitems.vue"),
		meta: {
            title: '服务项目'
        }
	}, {
		path: "/servicedetail",
		name: "servicedetail",
		component: () => import("../views/servicedetail/servicedetail.vue"),
		meta: {
            title: '国际货代'
        }
	}, {
		path: "/serviceclearance",
		name: "serviceclearance",
		component: () => import("../views/serviceclearance/serviceclearance.vue"),
		meta: {
			title: '进出口报关'
		}
	},
	{
		path: "/servicegoods",
		name: "servicegoods",
		component: () => import("../views/servicegoods/servicegoods.vue"),
		meta: {
			title: '仓储服务'
		}
	},
	{
		path: "/servicebonded",
		name: "servicebonded",
		component: () => import("../views/servicebonded/servicebonded.vue"),
		meta: {
			title: '跨境保税'
		}
	},
	{
		path: "/servicedeputy",
		name: "servicedeputy",
		component: () => import("../views/servicedeputy/servicedeputy.vue"),
		meta: {
			title: '贸易代理'
		}
	},
	{
		path: "/servicepinkoi",
		name: "servicepinkoi",
		component: () => import("../views/servicepinkoi/servicepinkoi.vue"),
		meta: {
			title: '文创中心'
		}
	},
	{
		path: "/joinus",
		name: "joinus",
		component: () => import("../views/joinus/joinus.vue"),
		meta: {
            title: '加入我们'
        }
	}, {
		path: "/mileage",
		name: "mileage",
		component: () => import("../views/mileage/mileage.vue"),
		meta: {
            title: '丝路里程'
        }
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	// 让页面回到顶部
	document.documentElement.scrollTop = 0
	// 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
	next()
})

export default router;
