<template>
  <router-view />
</template>

<style lang="scss">
#app {
    font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.h1, .h2, .h3, h1, h2, h3 {
	margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.case-box-hover {
    width: calc(100% / 4 - 20px);
    float: left;
    transition: all .2s;
    overflow: hidden;
    background: #fff;
    margin: 10px;
    padding: 20px 10px 10px 10px;
    cursor: pointer;
}

.case-box-hover:hover {
    transform:translateY(-1px);
    box-shadow: 0px 6px 14px 0px rgba(38,41,113,0.20);
}

li {
	list-style: none;
}

ol, ul {
	margin-bottom: 0 !important;
}

input {
	background: none;  
	outline: none;  
	border: none;
}

.container-fluid {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.bigTietle {
    text-align: center;
    line-height: 40px;
    font-size: 28px;
    font-weight: 600;
    color: #191919;
}

.bigTietle>span {
    position: relative;
    padding-left: 87px;
    display: inline-block;
}

.smallLogo {
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
}

.news {
    font-size: 14px;
    text-align: center;
    color: #757575;
    line-height: 16px;
    margin-bottom: 44px;
    margin-top: 10px;
}

.bannerImg {
    overflow: hidden;
    position: relative;
}

.bannerImg>img {
    width: 100%;
}

.bannerImg>div {
    color: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
}

.bannerImg .container h2 {
    font-size: 48px;
    font-weight: 500;
    line-height: 58px;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.50);
}

.bannerImg .container p {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 63px;
    margin-top: 20px;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.50);
}

.newsList {
    margin-top: 72px;
    margin-bottom: 80px;
}

.article>h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #333333;
    line-height: 33px;
}

.article>h5 {
    font-size: 12px;
    text-align: center;
    color: #757575;
    line-height: 14px;
}

.articleDetail {
    margin-top: 30px;
}

.articleDetail>p {
    font-size: 14px;
    color: #333333;
    line-height: 25px;
    margin-top: 10px;
}

.articleDetail>img {
    width: 100%;
}

.contactUs {
    width: 100%;
    overflow: hidden;
    padding: 40px;
    background: #f6f6f6;
}

.contactTitle {
    font-size: 20px;
    font-weight: 500;
    color: #262971;
    line-height: 28px;
}

.contactName {
    font-size: 20px;
    font-weight: 500;
    color: #191919;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 8px;
}

.contactUs>p {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 26px;
}

@media screen and (max-width:992px){
    .bannerBox {
        margin-top: 50px;
    }

    .bannerImg > img {
        height: 192px;
    }

    .bannerImg .container  p {
        margin-top: 10px;
        margin-bottom: 24px;
    }
}

@media screen and (max-width:767px){
    .bannerBox {
        margin-top: 50px;
    }

    .bannerImg h2 {
        font-size: 24px;
        line-height: 30px;
        margin-top: 36px !important;
    }
    
    .bannerImg p {
        font-size: 16px;
        line-height: 22px;
        text-shadow: 2px 2px 2px rgba(0,0,0,0.50);
        margin-top: 3px;
        margin-bottom: 12px;
    }

    .bannerImg > img {
        height: 102px;
    }

    .bannerImg .container h2 {
        font-size: 24px;
        line-height: 29px;
    }

    .bannerImg .container  p {
        font-size: 16px;
        line-height: 22px;
        margin-top: 3px;
        margin-bottom: 12px;
    }
}
</style>
