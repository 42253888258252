import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import './assets/iconfont/iconfont.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min.js'


createApp(App).use(store).use(router).mount("#app");
